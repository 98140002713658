import React, { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import { Box, Tabs, Tab, Typography, ListItem, ListItemIcon, ListItemText, Grid } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import darkTheme from './themes';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Badge from '@mui/material/Badge';

function App() {
  const [data, setData] = useState(null);
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getData = (data) => {
    let d = new Map([]);

    data.forEach(c => Object.keys(c).forEach((key) => {
      if (key.indexOf('EMPTY') < 0) {
        if (d.has(key)) {
          d.set(key, [...d.get(key)].concat(c[key]));
        }
        else {
          d.set(key, Array(c[key]));
        }
      }
    }));

    return d;
  }

  useEffect(() => {
    (async () => {
      /* Download file */
      const f = await (await fetch("https://docs.google.com/spreadsheets/d/e/2PACX-1vTayT_zvakMfdErLXFoEyXVtN1Hnpx1QsSf7JX825TVIz5kmRc_L2jTP4ULQWaSXEVQhtlzOr1Zdiqm/pub?output=xlsx")).arrayBuffer();
      const wb = read(f); // parse the array buffer

      var sheets = [];
      wb['SheetNames'].forEach(c => {
        sheets.push({
          name: c,
          data: getData(utils.sheet_to_json(wb.Sheets[c]))
        });
      });

      setData(sheets);
    })();
  }, []);

  function printData(data) {
    var lists = [];

    for (const d of data.keys()) {
      lists.push(
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Accordion key={`list-list-${lists.length}`}>
            <AccordionSummary
              expandIcon={<Badge color="primary" badgeContent={data.get(d).filter((c, i) => c.indexOf('(x)') >= 0).length} max={999}><ExpandMoreIcon /></Badge>}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {d}
            </AccordionSummary>
            <AccordionDetails>
              {printPerson(data.get(d))}
            </AccordionDetails>
          </Accordion>
        </Grid>)
    }

    return lists;
  }

  function printPerson(data) {
    let scored = 0;


    let people = data.map((c, i) => {
      let name = c;
      let isDead = false;
      if (name.indexOf('(x)') >= 0) {
        isDead = true;
        scored++;
        name = name.replace('(x)', '').trim();
      }
      return (
        <ListItem disablePadding key={'list-item-' + c + ' ' + i}>
          <ListItemIcon>
            {!isDead ? <CheckIcon sx={{ color: "green" }} /> : <CloseIcon sx={{ color: "red" }} />}
          </ListItemIcon>
          <ListItemText primary={name} />
        </ListItem>
      )
    });

    people.push(<ListItem disablePadding key={'list-item-score'}>
      <ListItemText primary={`Score: ${scored}`} sx={{ fontWeight: 600 }} />
    </ListItem>)

    return people;
  }

  return data && (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', textAlign: 'center' }} >
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            {data.map((c, i) => {
              return (<Tab id={'tab-' + i} label={c.name} key={'tab-' + i} />)
            })}
          </Tabs>
          {data.map((c, i) => {
            return (<CustomTabPanel value={value} index={i} key={'tab-panel-' + i}>
              <Grid container spacing={2}>
                {printData(c.data)}
              </Grid>
            </CustomTabPanel>)
          }
          )}
        </Box >
      </>
    </ThemeProvider>);
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default App;
